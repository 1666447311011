<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) What is the IUPAC name of the compound shown below (ignoring stereochemistry)?
      </p>

      <p class="mb-2 pl-12">
        <v-img
          src="/img/assignments/laurierSandboxStructureQuestion2.png"
          alt="A picture of a molecule with a cyclopentane with 3 substituents. The three substituents are an isopropyl group on one carbon, a two carbon chain two carbons away, and a one carbon chain three carbons away."
          max-width="213px"
        />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'LaurierSandboxQ2',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {
          text: '1-methyl-2-ethyl-4-isopropylcyclopentane',
          value: '1-methyl-2-ethyl-4-isopropylcyclopentane',
        },
        {
          text: '1-isopropyl-3-ethyl-4-methylcyclopentane',
          value: '1-isopropyl-3-ethyl-4-methylcyclopentane',
        },
        {
          text: '1-ethyl-2-methyl-4-isopropylcyclopentane',
          value: '1-ethyl-2-methyl-4-isopropylcyclopentane',
        },
        {
          text: '1-ethyl-4-isopropyl-2-methylcyclopentane',
          value: '1-ethyl-4-isopropyl-2-methylcyclopentane',
        },
      ],
    };
  },
};
</script>
